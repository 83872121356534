import { browser } from "$app/environment"
import { BehaviorSubject } from "rxjs"

function setting<T>(key: string, fallback: T) {
    console.log("Create setting", key, fallback)

    const value = browser ? window.localStorage.getItem(key) : null

    let obj: T | null
    try {
        obj = value ? JSON.parse(value) : null
    } catch (error) {
        obj = null
    }

    const subject = new BehaviorSubject<T>(obj ?? fallback)
    if (browser)
        subject.subscribe((value) => {
            console.log("Settings", key, "got new value", value)
            window.localStorage.setItem(key, JSON.stringify(value))
        })
    return subject
}

function settingSet<T>(key: string, fallback: Set<T>) {
    console.log("Create setting", key, fallback)

    const value = browser ? window.localStorage.getItem(key) : null

    let obj: Array<T> | null
    try {
        obj = value ? JSON.parse(value) : null
        if (obj && !Array.isArray(obj)) obj = null
    } catch (error) {
        obj = null
    }

    const subject = new BehaviorSubject<Set<T>>(obj ? new Set(obj) : fallback)
    if (browser)
        subject.subscribe((value) => {
            console.log("Settings", key, "got new value", value)
            window.localStorage.setItem(key, JSON.stringify(Array.from(value)))
        })
    return subject
}

/// The name of the user
export const name$ = setting<string>("name", "")

export const microphoneDeviceId$ = setting<string | null>("microphoneDeviceId", "default")
export const cameraDeviceId$ = setting<string | null>("cameraDeviceId", null)
export const screenResolutionConstraint$ = setting<number | null>(
    "screenResolutionConstraint",
    null,
)

/// The volume multiplier of `UserId`-hashes ("Visitor/Gustav")
export const rtcAudioVolume$ = setting<Record<string, number>>("rtcAudioVolume", {})

// ! Settings
export const lightDarkMode$ = setting<"system" | "light" | "dark">("lightDarkMode", "system")
export const playSoundsGeneral$ = setting<boolean>("playSoundsGeneral", true)
export const showChatTabNotificationBadge$ = setting<boolean>("showChatTabNotificationBadge", true)

// ! Tutorial
export const tutorialsHidden$ = settingSet<string>("tutorialsHidden", new Set())
export const tutorialsHideAll$ = setting<boolean>("tutorialsHideAll", false)
